'use client';
import { WebglSwirlLine } from '@/components/commonComponents/webglSwirlLine/webglSwirlLine';
import { gsap } from 'gsap';
import { useEffect, useState } from 'react';

import { Icon } from '../../internalComponents/icon';
import { Hero, HeroProps } from './hero';
import styles from './hero.module.scss';

const preTitle = [
  {
    title: 'Javascript',
    background: '#13ca91',
  },
  {
    title: 'Front-end',
    background: '#13ca91',
  },
  {
    title: 'Full-stack',
    background: '#13ca91',
  },
  {
    title: 'React',
    background: '#61dafb',
  },
  {
    title: 'Typescript',
    background: '#3178c6',
  },
  {
    title: 'Vue.js',
    background: '#42b883',
  },
  {
    title: 'Angular',
    background: '#dd0031',
  },
  {
    title: 'Node.js',
    background: '#8cc84b',
  },
];

const pretitleCapitalized = preTitle.map((item) => item.title.toUpperCase());

export const HomeHero = ({ subject }: HeroProps) => {
  const [position, setPosition] = useState(0);

  useEffect(() => {
    const timeline = gsap.timeline({ repeat: -1 });

    timeline.to(
      {},
      {
        duration: 5,
        onComplete() {
          setPosition((current) => (current + 1) % preTitle.length);
        },
      }
    );

    return () => {
      timeline.kill();
    };
  }, []);

  return (
    <Hero
      subject={subject}
      fullHeight
      background={
        <WebglSwirlLine
          className={styles.webgl}
          color={preTitle[position].background}
          topTexts={pretitleCapitalized}
          bottomText={'Bazen'.toUpperCase()}
          activeTopTextIndex={position}
          textAs="h1"
        />
      }
      bottomContent={
        <div className={styles.bottomBar}>
          <Icon className={styles.bottomBarMouseIcon} name="MouseScroll" />
          <Icon className={styles.bottomBarArrowIcon} name="ArrowDown" />
          <span className={styles.bottomBarText}>Scroll dan!</span>
        </div>
      }
    />
  );
};
