import { ReactElement, ReactNode } from 'react';

import styles from './hero.module.scss';

export interface HeroProps {
  title?: string;
  children?: ReactNode;
  subject?: string | ReactNode;
  fullHeight?: boolean;
  bottomContent?: ReactNode;
  background?: ReactElement;
  backgroundColor?: string;
  surface?: 'neutral' | 'inverted';
  webglText?: {
    activeTopTextIndex?: number;
    topTexts?: ReadonlyArray<string>;
    bottomText?: string;
  };
}

export const Hero = ({
  title,
  children,
  subject,
  bottomContent,
  fullHeight,
  background,
  backgroundColor = '#13ca91',
}: HeroProps) => {
  return (
    <div
      className={`${styles.heroWrapper} ${fullHeight ? styles.fullHeight : ''}`}
    >
      <section
        className={`${styles.hero}`}
        style={{
          backgroundColor: backgroundColor,
        }}
      >
        {background}
        <div className={styles.contentWrapper}>
          {title && <h1 className={styles.title}>{title}</h1>}
          {children}
          {subject && <div className={styles.subject}>{subject}</div>}
        </div>
      </section>
      {bottomContent}
    </div>
  );
};
